import * as React from "react";
import "beercss";
import { Skeleton, Typography } from "@mui/material";

const BlogSkeleton = () => {
  const skeletonMap = [
    {
      img: <Skeleton variant="rectangular" width={210} height={118} />,
      title: <Skeleton />,
      desc: <Skeleton />,
    },
    {
      img: <Skeleton variant="rectangular" width={210} height={118} />,
      title: <Skeleton />,
      desc: <Skeleton />,
    },
  ];
  return (
    <div className="grid" style={{ padding: 20 }}>
      {skeletonMap.map((sm) => (
        <div className="s6">
          <article className="no-padding border">
            <div className="padding">
              {sm.img}
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
                noWrap
              >
                {sm.title}
              </Typography>
              <Typography
                // variant="p"
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                noWrap
              >
                {sm.desc}
              </Typography>
            </div>
          </article>
        </div>
      ))}
    </div>
  );
};

const ReadBlogSkeleton = () => {
  return (
    <>
      <Typography variant="h2">
        <Skeleton width="80%" animation="wave" />
      </Typography>
      <Skeleton sx={{ height: 200 }} animation="wave" variant="rounded" />
    </>
  );
};

export { BlogSkeleton, ReadBlogSkeleton };
