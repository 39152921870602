import * as React from "react";
import axios from "axios";
import "beercss";
import { useSearchParams } from "react-router-dom";
import {
  ParagraphBlock,
  HeaderTwo,
  ImageBlock,
  NumberedList,
  CodeText,
} from "../components/BlockMapper";
import '../css/fonts.scss';
import { Snackbar, Alert } from "@mui/material";
import { ReadBlogSkeleton } from "../components/SkeletonLoader";

export default function ReadDets(){
    const [isLoading, setIsLoading] = React.useState(true);
  const [content, setContent] = React.useState([]);
  const [searchParams] = useSearchParams();
  const [shareCopied, setSharedCopied] = React.useState(false);

  React.useEffect(() => {
    getContent();
  }, []);

  const getContent = async () => {
    const experienceId = searchParams.get("blogid");
    const resp = await axios.get(
      `${process.env.REACT_APP_API_URL}/content?blogId=${experienceId}`
    );
    setContent(resp.data["data"]);
    setIsLoading(false);
  };

  const handleBack = () => {
    window.history.back();
  }

  return (
    <>
      {isLoading ? (
        <ReadBlogSkeleton />
      ) : (
        <>
          <header className="fixed" style={{ backgroundColor: 'black' }}>
            <nav>
              <a onClick={handleBack}>
                <button class="circle transparent">
                  <i>arrow_back</i>
                </button>
              </a>
            </nav>
          </header>
          <div style={{ padding: 30 }}>
            {content.map((c) => {
              if (c.type === "paragraph") {
                return <ParagraphBlock block={c} key={c.id} />;
              } else if (c.type === "heading_2") {
                return <HeaderTwo block={c} key={c.id} />;
              } else if (c.type === "image") {
                return <ImageBlock block={c} key={c.id} />;
              } else if (c.type === "numbered_list_item") {
                return <NumberedList block={c} key={c.id} />;
              } else if (c.type === "code") {
                return <CodeText block={c} key={c.id} />;
              }
              return "";
            })}
          </div>
        </>
      )}
      <Snackbar open={shareCopied} autoHideDuration={5000} onClose={() => setSharedCopied(false)}>
        <Alert onClose={() => setSharedCopied(false)} severity="success" sx={{ width: '100%' }}>
          Blog Link Copied
        </Alert>
      </Snackbar>
    </>
  );
}