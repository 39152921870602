import { Typography } from "@mui/material";
import * as React from "react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../css/fonts.scss';

const ParagraphBlock = ({ block }) => {
  const paraText = block.paragraph.rich_text
    .map((textObj) => textObj.text.content)
    .join("");
  return <Typography variant="body1" fontSize={18}>{paraText}</Typography>;
};

const HeaderOne = ({ block }) => {
  const headerOne = block.heading_1.rich_text[0].text.content;
  return <Typography variant="h1">{headerOne}</Typography>;
};

const HeaderTwo = ({ block }) => {
  const headerTwo = block.heading_2.rich_text[0].text.content;
  return <Typography variant="h2" style={{ fontSize: 30, fontWeight: 'bold' }}>{headerTwo}</Typography>;
};

const HeaderThree = ({ block }) => {
  const headerThree = block.heading_3.rich_text[0].text.content;
  return <Typography variant="h3">{headerThree}</Typography>;
};

const NumberedList = ({ block }) => {
  const textContent = block.numbered_list_item.rich_text
    .map((textObj) => textObj.text.content)
    .join("");
  // const linkUrl = block.numbered_list_item.rich_text[1].text.link.url;
  return (
    <li>
      <Typography variant="body1">{textContent}</Typography>
      {/* <a href={linkUrl}>{linkUrl}</a> */}
    </li>
  );
};

const CodeText = ({ block }) => {
  const codeContent = block.code.rich_text[0].text.content;
  const language = block.code.language;

  return (
    <SyntaxHighlighter
      language={language}
      showLineNumbers={true}
      style={oneLight}
    >
      {codeContent}
    </SyntaxHighlighter>
  );
};

const ImageBlock = ({ block }) => {
  const img = block.image.external.url;
  return <img src={img} alt="" />;
};

export {
  ParagraphBlock,
  HeaderOne,
  HeaderTwo,
  HeaderThree,
  NumberedList,
  CodeText,
  ImageBlock,
};
